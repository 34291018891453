var DS = window.DS || {};
DS.app = function() {
    var els = {};
    var vars = {};

    var listen = function() {
        els.window.on('resize',resize);
    };

    var resize = function() {
        var newScreenWidth = els.window.innerWidth();
        if(vars.currScreenWidth < vars.desktopBreakpoint && newScreenWidth >= vars.desktopBreakpoint
            || vars.currScreenWidth >= vars.desktopBreakpoint && newScreenWidth < vars.desktopBreakpoint) {
            reloadPage();
        }
        vars.currScreenWidth = newScreenWidth;
    };

    var reloadPage = function() {
        window.location.reload();
    };

    return {
        init: function(el) {
            var $el = $(el);
            els = {
                component: $el,
                window: $(window)
            };

            vars = {
                mapReady: false,
                currScreenWidth: els.window.innerWidth(),
                desktopBreakpoint: 769
            };

            $(function() {
                $('.slick-arrow').attr('tabindex','0');
                $('.carousel-component .slide').not('.slide:first-child').find('a').attr('tabindex','-1');
                listen();
            });
        },

        map: function() {
            vars.mapReady = true;
            els.window.trigger('googleMapsReady');
        },

        mapReady: function () {
            return vars.mapReady;
        },

        isMobile: function() {
            return /Mobi/i.test(navigator.userAgent) || /Android/i.test(navigator.userAgent) || !(window.matchMedia("(min-width: " + vars.desktopBreakpoint + "px)").matches);
        },

        isMobileWindowSize: function () {
            return !(window.matchMedia("(min-width: " + vars.desktopBreakpoint + "px)").matches);
        }
    };
};

window.app = new DS.app();
app.init();
