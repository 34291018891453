var DS = window.DS || {};
DS.roomsGuestsSelect = function() {
  var els = {};
  var vars = {};

  var listen = function() {
    els.component.siblings().find('input, button').on({
      focus: function() {
        if ( !els.roomsGuestsPanel.hasClass('is-hidden') ) {
          onRoomsGuestsClicked();
        }
      }
    });

    els.component.parents('.booking-component').on({
      click: function(evt) {
        evt.stopPropagation();
        var isRoomsGuestTrigger = $(evt.target).is(els.roomsGuestsTrigger);
        var clickedOutsideOfRoomsGuests = !vars.isMobile && !isRoomsGuestTrigger && els.roomsGuestsTrigger.has(evt.target).length === 0 && els.roomsGuestsPanel.has(evt.target).length === 0 && els.roomsGuestsPanel.is(":visible");

        // check if the clicked area is room guest dropdown or not
        // close room guest dropdown when clicked outside
        if (clickedOutsideOfRoomsGuests) {
          closeRoomsGuests();
        }
      }
    });

    els.stepperInput.on({
      focus: function() {
        $(this).select();
      },
      change: function() {
        parseStepperValue( $(this) );
      }
    });

    els.roomsGuestsTrigger.on({
      focus: function(evt) {
        evt.preventDefault();
        if ( !vars.isMobile ) {
          $(this).trigger('mousedown');
        }
      },
      mousedown: function(evt) {
        evt.preventDefault();
        if ( !vars.isMobile ) {
          onRoomsGuestsClicked();
        }
      }
    });

    els.plusMinus.on({
      click: stepperClickHandler
    });

    els.resetRoomsGuests.on({
      click: reset
    });
  };

  var parseStepperValue = function( $this, val ) {
    var number = val || val === 0 ? val : parseInt($this.val().match(/\d+/)[0]); // Get initial value from input string
    var rooms = $this.hasClass('js-rooms-num');
    var adults = $this.hasClass('js-adults-num');
    var children = $this.hasClass('js-children-num');
    var min = parseInt($this.data('min'));
    var max = parseInt($this.data('max'));
    var string;

    if ( number > max ) { number = max; }
    if ( number < min ) { number = min; }

    if ( rooms ) {
      string = getRoomsGuestsSingularPluralLabel( $this, number );
      updateRoomsGuestsFieldString('rooms', number);
    }
    else if ( adults ) {
      string = getRoomsGuestsSingularPluralLabel( $this, number );
      updateRoomsGuestsFieldString('adults', number);
    }
    else {
      string = getRoomsGuestsSingularPluralLabel( $this, number, 'children' );
      updateRoomsGuestsFieldString('children', number);
    }

    $this.val( number +' '+ string );
    els.screenReaderEl.html( number +' '+ string );
    $this.siblings('.stepper-num').text( number );
    $this.siblings('select').val( number );
    toggleStepperButtons( $this, number, min, max );
  };

  var onRoomsGuestsClicked = function() {
    els.roomsGuestsPanel.slideToggle("fast")
      .attr('aria-expanded', els.roomsGuestsPanel.attr('aria-expanded') === 'false' ? 'true' : 'false')
      .toggleClass('is-hidden')
      .parents(".form-group")
      .find('.dropdown-trigger')
      .toggleClass('dropdown-is-active');
  };

  var closeRoomsGuests = function() {
    els.roomsGuestsPanel.slideUp('fast')
      .attr('aria-expanded','false')
      .addClass('is-hidden')
      .parents(".form-group")
      .find('.dropdown-trigger')
      .removeClass('dropdown-is-active');
  };

  var getRoomsGuestsSingularPluralLabel = function( $this, _val, _field ) {
    if( _field === 'children' ) {
      return $this.data( (_val > 1 || _val === 0) ? 'plural' : 'singular');
    }
    return $this.data( (_val > 1) ? 'plural' : 'singular' );
  };

  var stepperClickHandler = function(evt) {
    evt.preventDefault();
    var $el = $(this);
    var input = $el.parent().find('input');
    var val = parseInt(input.val().match(/\d+/)[0]); // Get initial value from input string
    var min = parseInt(input.data('min'));
    var max = parseInt(input.data('max'));
    var minus = $el.hasClass('js-minus');
    var plus = $el.hasClass('js-plus');

    (plus && val < max) ? val++ : (minus && val > min) ? val-- : false;
    parseStepperValue( input, val );
  };

  var updateRoomsGuestsFieldString = function( field, val ) {
    var textElem;
    var valueElem;

    if ( field === 'children' ) {
      textElem = '.js-children-text';
      valueElem = '.js-number-of-children';
    }
    else if ( field === 'adults' ) {
      textElem = '.js-adults-text';
      valueElem = '.js-number-of-adults';

    } else {
      textElem = '.js-rooms-text';
      valueElem = '.js-number-of-rooms';
    }

    els.roomsGuestsTrigger.find( valueElem ).text( val );
    textElem = els.roomsGuestsTrigger.find( textElem );
    textElem.html( getRoomsGuestsSingularPluralLabel( textElem, val, field === 'children' ? 'children':null ) );
  };

  var toggleStepperButtons = function( $el, val, min, max ) {
    var $el_minus = $el.siblings('.js-minus');
    var $el_plus = $el.siblings('.js-plus');

    $el_minus.removeClass('inactive').attr('aria-disabled', false);
    $el_plus.removeClass('inactive').attr('aria-disabled', false);

    if (val === max) {
      $el_plus.addClass('inactive').attr('aria-disabled', true);
    }
    else if (val === min) {
      $el_minus.addClass('inactive').attr('aria-disabled', true);
    }
  };

  var reset = function(evt) {
    evt.preventDefault();
    els.stepperInput.each(function(){
      var $this = $(this);
      var min = $this.data('min');

      parseStepperValue( $this, min );
      $this.siblings('.stepper-num').text( min );
      $this.siblings('select').val( min );
    });
  };

  return {
    init: function(el) {
      var $el = $(el);

      els = {
        component: $el,
        formGroup: $el.find('.form-group'),
        plusMinus: $el.find('.plus, .minus'),
        roomsGuestsTrigger: $el.find('.js-rooms-guests-trigger'),
        roomsGuestsPanel: $el.find('.js-rooms-guests-panel'),
        roomsGuestsSelect: $el.find('.rooms-guests-select'),
        stepperInput: $el.find('.stepper-input'),
        resetRoomsGuests: $el.find('.reset-rooms-guests'),
        screenReaderEl: $el.find('.acc-hidden')
      };
      vars = {
        isMobile: window.app.isMobileWindowSize()
      };

      listen();
    }
  };
};

$(function() {
  var $components = $('.rooms-guests-select');
  if ( $components.length ) {
    $components.each(function(i,el) {
      var roomsGuestsSelect = new DS.roomsGuestsSelect();
      roomsGuestsSelect.init(el);
    });
  }
});
