var DS = window.DS || {};
DS.accordion = function(){
    var els = {};
    var vars = {};

    var listen = function(){
        els.accordion.find('> dt > a').on('click', accordionToggle);
    };

    var accordionToggle = function (e) {
        e.preventDefault();

        els.component.find('.open').removeClass('open');

        if (!$(this).parent().next().is(":visible")) {
            $(this).parent().addClass('open').next().addClass('open');
        }

        els.allPanels.slideUp();
        els.component.find('dd.open').slideDown();

        return false;
    }

    return {
        init: function(el){
            var $el = $(el);
            els = {
                component: $el,
                accordion: $el.find('.accordion'),
                allPanels: $el.find('.accordion > dd')
            };

            vars = {};

            listen();
        }
    };
};

$(function(){
    if ( $('.accordion-component').length > 0 ){
        $('.accordion-component').each(function(i,el){
            var accordion = new DS.accordion();
            accordion.init(el);
        });
    }
});
