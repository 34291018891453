var DS = window.DS || {};

DS.verticalGridBlockGallery = function (el) {
  var els = {};
  var $document = $(document);

  var init = function () {
    els.component = $(el);
    els.modal = els.component.find('.vertical-grid-block__modal');
    els.openModalButtons = els.component.find('.action-open-modal');
    els.closeModalButtons = els.component.find('.action-close-modal');
    els.previousButtons = els.component.find('.action-previous');
    els.nextButtons = els.component.find('.action-next');
    els.imageItems = els.component.find('.vertical-grid-block__modal__image-item');
  };

  var open = function () {
    els.modal.addClass('vertical-grid-block__modal--state-open');
    els.modal.removeAttr('aria-hidden');
  };

  var close = function () {
    els.modal.removeClass('vertical-grid-block__modal--state-open');
    els.modal.attr('aria-hidden', true);
  };

  var previous = function () {
    var $activeElement = els.imageItems.filter('.vertical-grid-block__modal__image-item--active');
    els.imageItems.removeClass('vertical-grid-block__modal__image-item--active');
    els.imageItems.attr('aria-hidden', true);
    var $previousItem = $activeElement.is(':first-child')
      ? els.imageItems.last()
      : $activeElement.prev();
    $previousItem.addClass('vertical-grid-block__modal__image-item--active');
    $previousItem.removeAttr('aria-hidden');
    $previousItem.focus();
  };

  var next = function () {
    var $activeElement = els.imageItems.filter('.vertical-grid-block__modal__image-item--active');
    els.imageItems.removeClass('vertical-grid-block__modal__image-item--active');
    els.imageItems.attr('aria-hidden', true);
    var $nextItem = $activeElement.is(':last-child')
      ? els.imageItems.first()
      : $activeElement.next();
    $nextItem.addClass('vertical-grid-block__modal__image-item--active');
    $nextItem.removeAttr('aria-hidden');
    $nextItem.focus();
  };

  var addListeners = function () {
    $document.on('keyup', function (event) {
      var code = (event.keyCode ? event.keyCode : event.which);
      if (code === 27) open();
    });

    els.openModalButtons.click(function () {
      open();
    });

    els.closeModalButtons.click(function () {
      close();
    });

    els.previousButtons.click(function () {
      previous();
    });

    els.nextButtons.click(function () {
      next();
    });
  };

  init();
  addListeners();
};

DS.verticalGrid = function (el) {
  var vars = {};
  var els = {};

  var listen = function () {
    if(vars.hasLoadMore) {
      els.loadMore.on('click', function(){
        var hiddenBlocks = els.component.find('.vertical-grid-block.hide:not(.filtered-out)');
        var blocksToReveal = hiddenBlocks.slice(0,vars.loadInterval);
        blocksToReveal.removeClass('hide');
        if(hiddenBlocks.length <= blocksToReveal.length) {
          els.loadMore.hide();
        }
        if (vars.hasIsotope) {
          els.blockList.isotope(vars.isotopeOptions);
        }
      });
    }
    els.filterItems.click(function (event) {
      event.preventDefault();
      var $currentTarget = $(this);
      var filterValue = $currentTarget.data('filterValue');
      els.filterItems.not($currentTarget).removeClass('vertical-grid__filter-item--state-active');
      $currentTarget.addClass('vertical-grid__filter-item--state-active');
      if (filterValue === 'all') {
        els.blockList.isotope({
          filter: function() {
            $(this).removeClass('filtered-out');
            return true;
          }
        });
      } else {
        els.blockList.isotope({
          filter: function() {
            var $block = $(this);
            var blockType = $block.attr('data-block-type');
            $block.toggleClass('filtered-out',blockType !== filterValue);
            return blockType === filterValue;
          }
        });
      }
      updateLoadMore();
    });
  };

  var updateLoadMore = function() {
    if(vars.hasLoadMore) {
        var filteredInBlocks = els.component.find('.vertical-grid-block:not(.filtered-out)');
        var blocksToReveal = filteredInBlocks.slice(0,vars.loadInterval);
        var blocksToHide = filteredInBlocks.not(blocksToReveal);
        blocksToReveal.removeClass('hide');
        blocksToHide.addClass('hide');
        els.loadMore.toggle(filteredInBlocks.length > vars.loadInterval);
        if (vars.hasIsotope) {
          var isotopeOptionsClone = jQuery.extend(true, {}, vars.isotopeOptions);
          isotopeOptionsClone.itemSelector = '.vertical-grid-block:not(.hide)';
          els.blockList.isotope(isotopeOptionsClone);
        }
    }
  }

  return {
    init: function (el) {
      var $el = $(el);

      els = {
        component: $el,
        loadMore : $el.find('.vertical-grid__controls .action-show'),
        blockList: $el.find('.vertical-grid__block-list'),
        filterItems: $el.find('.vertical-grid__filter-item')
      };

      vars = {
        loadInterval: $el.attr('data-load-interval'),
        hasIsotope: !$el.hasClass('no-isotope'),
        hasLoadMore: $el.hasClass('load-more'),
        isInverted: $el.hasClass('inverted'),
        isStaggered: $el.hasClass('staggered'),
        isOffset: $el.hasClass('offset'),
        stagger: $el.attr('data-stagger'),
        layout: 'spineAlign',
        layoutOptions: {
          col1Height: 0,
          col2Height: 0,
          col1WidthPercent: 0.645,
          col2WidthPercent: 0.315,
        },
      };

      if(vars.isStaggered) {
        if(vars.stagger && parseInt(vars.stagger) > 0) {
          vars.layoutOptions.col2Height = parseInt(vars.stagger);
        }
      }

      if(vars.isOffset) {
        vars.layout = 'spineAlignOffset';
        if(vars.isInverted) {
          var tempCol1Height = vars.layoutOptions.col1Height;
          var tempCol1WidthPercent = vars.layoutOptions.col1WidthPercent;
          vars.layoutOptions.col1Height = vars.layoutOptions.col2Height;
          vars.layoutOptions.col1WidthPercent = vars.layoutOptions.col2WidthPercent;
          vars.layoutOptions.col2Height = tempCol1Height;
          vars.layoutOptions.col2WidthPercent = tempCol1WidthPercent;
        }
      }

      els.component.find('.vertical-grid-block--gallery').each(function (i, el) {
        new DS.verticalGridBlockGallery(el);
      });

      vars.isotopeOptions = {
        itemSelector: '.vertical-grid-block',
        layoutMode: vars.layout,
        transitionDuration: 0
      };
      vars.isotopeOptions[vars.layout] = vars.layoutOptions;

      els.blockList.find('img').on('load', function(){
        els.blockList.isotope();
      });

      els.blockList.isotope(vars.isotopeOptions);

      listen();
    }
  };
};

$(function () {
  if (!$('.vertical-grid').length) return;
  $('.vertical-grid').each(function (i, el) {
    var vGrid = new DS.verticalGrid();
    vGrid.init(el);
  });
});
