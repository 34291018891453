var DS = window.DS || {};
DS.dateSelect = function() {
    var els = {};
    var vars = {};

    var listen = function() {
        els.component.on({
            focus: function() {
                // $(this).select();
                if ( vars.isChildOfBookingComponent && !vars.isMobile && !els.datePicker.is(":visible") ) {
                    els.datePicker.slideToggle('fast');
                }
            }
        }, 'input')
            .on({
                click: function() {
                    resetDatesAndRange();
                    els.dateFrom.focus();
                }
            }, '.ui-datepicker-clear')
            .on({
                click: function() {
                    els.datePicker.slideToggle('fast');
                }
            }, '.ui-datepicker-close');

        // Hide datepicker when switching fields
        els.component.siblings().find('input, button, a').on({
            focus: function() {
                if ( els.datePicker.is(":visible") ) {
                    els.datePicker.slideToggle('fast');
                }
            }
        });

        els.component.parents('.booking-component').on({
            click: function(evt) {
                evt.stopPropagation();
                var isDatePickerToggle = $(evt.target).is(els.togglePicker);
                var isPickerHeader = $(evt.target).parents('.ui-datepicker-header').length;
                var isButtonPane = $(evt.target).parents('.ui-datepicker-buttonpane').length;
                var clickedOutideOfDatepicker = !isDatePickerToggle && !isPickerHeader && !isButtonPane && els.togglePicker.has(evt.target).length === 0 && els.datePicker.is(":visible");

                // check if the clicked area is datepicker or not
                // close datepicker when clicked outside
                if (clickedOutideOfDatepicker) {
                    els.datePicker.slideUp('fast');
                }
            }
        });

        els.dateFrom.on({
            focus: function() {
                vars.currentFocusedDateField = 'arrival_date';
                els.datePicker.datepicker('option', 'minDate', 0);
                els.datePicker.datepicker('setDate', new Date(vars.arrivalDate*1000));

                if ( !vars.isMobile && !els.datePicker.is(":visible") ) {
                    els.datePicker.slideToggle('fast');
                }
                else if (!vars.isMobile) {
                    els.datePicker.insertAfter( $(this).parent() );
                }
            },
            change: function(evt) {
                updateArrivalDate( evt, $(this) );
            }
        });

        els.dateTo.on({
            focus: function() {
                vars.currentFocusedDateField = 'departure_date';
                els.datePicker.datepicker('setDate', new Date(vars.departureDate*1000));

                if ( !vars.isMobile && !els.datePicker.is(":visible") ) {
                    els.datePicker.slideToggle('fast');
                }
                else if (!vars.isMobile) {
                    els.datePicker.datepicker('option', 'minDate', 0);
                    els.datePicker.insertAfter( $(this).parent() );
                }
            },
            change: function(evt) {
                updateDepartureDate( evt, $(this) );
            }
        });
    };

    // _addDaysNum optional
    var getUnixTime = function( _date, _addDaysNum ) {
        var _d = Sugar.Date( _date );
        return Sugar.Date.format( _addDaysNum ? _d.addDays( _addDaysNum ): _d, '{X}');
    };

    var getDateForHiddenFields = function( _date, _addDaysNum ) {
        var _d = Sugar.Date( _date );
        return Sugar.Date.format( _addDaysNum ? _d.addDays( _addDaysNum ): _d, vars.dateQueryFormat ? vars.dateQueryFormat : '%m/%d/%Y');
    };

    var getDateForDisplay = function( _date, _addDaysNum ) {
        var _d = Sugar.Date( _date );
        var displayDate = Sugar.Date.format( _d ? _addDaysNum ? _d.addDays( _addDaysNum ) : _d : new Date(), '%a, %b %d');
        return displayDate;
    };

    var getDateRange = function( _fromDate, _toDate ) {
        return Sugar.Date.range( new Date(_fromDate), new Date(_toDate) ).days();
    };


    var updateArrivalDate = function( evt, $this ) {
        var _arrivaldate = Sugar.Date.create( $this.val() );

        if ( Sugar.Date( _arrivaldate ).isValid() && $this.val().length ) {
            var isValidBookingDate      = _arrivaldate <= vars.maxArrivalDate; // Check-in date must be <= 550 days from today
            var isMinimumCheckinDate    = getUnixTime( _arrivaldate ) >= getUnixTime(vars.minDate); // >= Today
            var isValidStayLength       = getDateRange( _arrivaldate, vars.dateToVal.raw) <= vars.stayLimit; // Less than 90 days
            var isValidDate             = isValidBookingDate && isMinimumCheckinDate;

            if ( isValidDate ) {
                // Arrival date is before departure date && length of stay is > 90 nights
                if ( getUnixTime(_arrivaldate) < vars.departureDate && !isValidStayLength ) {
                    alert( vars.errorStayLimit );
                    $this.val( getDateForDisplay( vars.dateFromVal.raw ) );
                }
                else {
                    _arrivaldate = getDateForDisplay( _arrivaldate );
                    $this.val( $this.val() );

                    els.arrivalDate.val( getDateForHiddenFields( _arrivaldate ) );
                    updateDatepickerRange($this, _arrivaldate);
                    els.datePicker.datepicker('setDate', vars.dateFromVal );
                }
            }
            else {
                if ( _arrivaldate.length ) {
                    alert( vars.errorInvalidDate );
                }
                else {
                    $this.val( getDateForDisplay( els.arrivalDate.val() ) );
                }
            }
        }
        else {
            $this.val( getDateForDisplay( els.arrivalDate.val() ) );
        }
    };

    var updateDepartureDate = function( evt, $this ) {
        var _departureDate = Sugar.Date.create( $this.val() );

        if ( Sugar.Date( _departureDate ).isValid() && $this.val().length ) {

            var isValidStayLength = getDateRange( vars.dateFromVal.raw, vars.dateToVal.raw ) <= vars.stayLimit; // Less than 90 days
            var isValidDate = getUnixTime( _departureDate.raw ) > getUnixTime( vars.minArrivalDate );

            if ( isValidDate ) {
                // Departure date is after arrival date && length of stay is > 90 nights
                if ( getUnixTime(_departureDate) > vars.arrivalDate && !isValidStayLength ) {
                    alert( vars.errorStayLimit );
                    $this.val( getDateForDisplay( els.departureDate.val() ) );
                }
                else {
                    _departureDate = getDateForDisplay( _departureDate );
                    $this.val( $this.val() );

                    els.departureDate.val( getDateForHiddenFields( _departureDate ) );
                    updateDatepickerRange($this, _departureDate);
                    els.datePicker.datepicker('setDate', vars.dateFromVal );
                }
            }
            else {
                if ( _departureDate.length ) {
                    alert( vars.errorInvalidDate );
                }
                else {
                    $this.val( getDateForDisplay( els.departureDate.val() ) );
                }
            }
        }
        else {
            $this.val( getDateForDisplay( els.departureDate.val() ) );
        }
    };

    var resetDatesAndRange = function() {
        var aDate = Sugar.Date.addDays(new Date(), 0, true);
        var dDate = Sugar.Date.addDays(new Date(), 1, true);

        vars.arrivalDate = Sugar.Date.format(new Date(aDate), '{X}'); // Unix timestamp
        vars.departureDate = Sugar.Date.format(new Date(dDate), '{X}'); // Unix timestamp

        vars.dateFromVal = Sugar.Date.format(new Date(aDate), '%a, %b %d');
        vars.dateToVal = Sugar.Date.format(new Date(dDate), '%a, %b %d');

        els.dateFrom.val( vars.dateFromVal );
        els.dateTo.val( vars.dateToVal );

        // Update hidden input values for search query
        els.arrivalDate.val( Sugar.Date.format(new Date(aDate), vars.dateQueryFormat ? vars.dateQueryFormat : '%m/%d/%Y') );
        els.departureDate.val( Sugar.Date.format(new Date(dDate), vars.dateQueryFormat ? vars.dateQueryFormat : '%m/%d/%Y') );
        els.datePicker.datepicker("refresh");
        updateNightsCount();
    };

    var updateDatepickerRange = function( $this, inputVal, range ) {
        var _date;

        if ( !$this ) {
            _date = Sugar.Date( inputVal );
            var _range = range ? range: 1;
            // Arrival date
            vars.dateFromVal = _date;
            vars.arrivalDate = _date.format('{X}').raw; // Unix timestamp
            els.arrivalDate.val( _date.format(vars.dateQueryFormat ? vars.dateQueryFormat : '%m/%d/%Y').raw );

            // Departure date
            vars.departureDate = _date.addDays(_range).format('{X}').raw; // Unix timestamp
            els.departureDate.val( _date.addDays(_range).format(vars.dateQueryFormat ? vars.dateQueryFormat : '%m/%d/%Y').raw );
        }
        else {
            _date = Sugar.Date( inputVal );

            // Update arrival date
            if ( $this.hasClass("date-from") ) {
                vars.dateFromVal = _date;
                vars.arrivalDate = _date.format('{X}').raw; // Unix timestamp
                els.arrivalDate.val( _date.format(vars.dateQueryFormat ? vars.dateQueryFormat : '%m/%d/%Y').raw );

                // If arrival date is the same or past the departure date
                if (vars.arrivalDate >= vars.departureDate ) {


                    vars.dateToVal = _date.addDays(1);
                    vars.departureDate = vars.dateToVal.format('{X}').raw;
                    els.dateTo.val( vars.dateToVal.format('%a, %b %d').raw );
                    els.departureDate.val( vars.dateToVal.format(vars.dateQueryFormat ? vars.dateQueryFormat : '%m/%d/%Y').raw );
                }
            }
            // Update departure date
            else {
                vars.dateToVal = _date;
                vars.departureDate = _date.format('{X}').raw; // Unix timestamp
                els.departureDate.val( _date.format(vars.dateQueryFormat ? vars.dateQueryFormat : '%m/%d/%Y').raw );

                // If departure date is before arrival date
                if (vars.departureDate <= vars.arrivalDate) {
                    vars.dateFromVal = _date.rewind('1 day');
                    vars.arrivalDate = vars.dateFromVal.format('{X}').raw;
                    els.dateFrom.val( vars.dateFromVal.format('%a, %b %d').raw );
                    els.arrivalDate.val( vars.dateFromVal.format(vars.dateQueryFormat ? vars.dateQueryFormat : '%m/%d/%Y').raw );
                }
            }
        }

        els.datePicker.datepicker("refresh");
        updateNightsCount();
    };

    var updateNightsCount = function() {
        vars.stayLength = getDateRange( els.arrivalDate.val(), els.departureDate.val() );
        var label = els.staylengthCount.data( vars.stayLength > 1 ? 'plural' : 'singular');
        els.staylengthCount.text( vars.stayLength+' '+label  );
    };

    var setRange = function( date ) {
        var a = vars.arrivalDate;
        var d = vars.departureDate;
        var time = date.getTime()/1000;

        var greaterThanToday = time > new Date();
        var lessThanMin = time < vars.arrivalDate;
        var minRange = time === Math.min(a, d);
        var maxRange = time === Math.max(a, d);
        var inBetweenRange = time > Math.min(vars.arrivalDate, vars.departureDate) && time <= Math.max(vars.arrivalDate, vars.departureDate);

        if (vars.currentFocusedDateField !== 'arrival_date') {
            if (lessThanMin && greaterThanToday) {
                return [true, 'ui-state-disabled']
            }
        }
        if (minRange) {
            return [true, 'date-range-min-date']
        }
        if (maxRange) {
            return [true, 'date-range-max-date']
        }
        return [true, ( inBetweenRange ? 'date-range-selected' : '')];
    };

    var setDefaultDates = function() {
        var d1_QueryStringFormat, d2_QueryStringFormat;
        var defaultArrivalDate = Sugar.Date.addDays(new Date(), 0, true);
        var defaultDepartureDate = Sugar.Date.addDays(new Date(), 1, true);

        vars.dateFromVal = defaultArrivalDate;
        vars.dateToVal = defaultDepartureDate;

        vars.arrivalDate = Sugar.Date.format(defaultArrivalDate,'{X}');
        vars.departureDate = Sugar.Date.format(defaultDepartureDate,'{X}');

        els.dateFrom.val( Sugar.Date(defaultArrivalDate).format('%a, %b %d') );
        els.dateTo.val( Sugar.Date(defaultDepartureDate).format('%a, %b %d') );

        d1_QueryStringFormat = Sugar.Date(defaultArrivalDate).format(vars.dateQueryFormat ? vars.dateQueryFormat : '%m/%d/%Y');
        d2_QueryStringFormat = Sugar.Date(defaultDepartureDate).format(vars.dateQueryFormat ? vars.dateQueryFormat : '%m/%d/%Y');

        els.arrivalDate.val( d1_QueryStringFormat );
        els.departureDate.val( d2_QueryStringFormat );

        els.staylengthCount.text( 1 + ' ' + els.staylengthCount.data('singular') );
    };

    var updateDates = function (dateText, inst) {
        var d1, d2, d1_QueryStringFormat, d2_QueryStringFormat;
        var selectedDate = (new Date(inst.selectedYear, inst.selectedMonth, inst.selectedDay));
        var time = selectedDate.getTime()/1000; // Unix time

        if ( vars.currentFocusedDateField === 'arrival_date' ) {
            els.datePicker.datepicker('setDate', selectedDate );
            els.datePicker.datepicker('option', 'minDate', 0);
            vars.arrivalDate = time;
        }
        else {
            vars.departureDate = time;
        }

        if ( vars.arrivalDate >= vars.departureDate ) {
            vars.departureDate = Sugar.Date( vars.arrivalDate*1000 ).addDays(1).format('{X}').raw;
        }

        var minDate = new Date(Math.min(vars.arrivalDate, vars.departureDate));
        var maxDate = new Date(Math.max(vars.arrivalDate, vars.departureDate));

        d1 = Sugar.Date( minDate*1000 ).format('%a, %b %d');
        d2 = Sugar.Date( maxDate*1000 ).format('%a, %b %d');
        els.dateFrom.val( d1 );
        els.dateTo.val( d2 );

        d1_QueryStringFormat = Sugar.Date( minDate*1000 ).format(vars.dateQueryFormat ? vars.dateQueryFormat : '%m/%d/%Y');
        d2_QueryStringFormat = Sugar.Date( maxDate*1000 ).format(vars.dateQueryFormat ? vars.dateQueryFormat : '%m/%d/%Y');

        els.arrivalDate.val( d1_QueryStringFormat );
        els.departureDate.val( d2_QueryStringFormat );

        vars.dateFromVal = d1;
        vars.dateToVal = d2;

        els.win.trigger('datepickerSelect');

        if ( vars.currentFocusedDateField === 'arrival_date' ) {
            els.dateTo.focus();
        }
    };

    var appendClearDoneButtons = function() {
        if ( !els.datePicker.find('.ui-datepicker-clear').length || !els.datePicker.find('.ui-datepicker-close').length ) {
            var clearText = els.datePicker.data('label-clear-btn');
            var doneText = els.datePicker.data('label-done-btn');

            $('<button type="button" class="ui-datepicker-clear ui-state-default ui-priority-primary ui-corner-all" data-handler="hide" data-event="click">' + clearText + '</button>')
                .appendTo(els.datePicker.find('.ui-datepicker-buttonpane'));

            $('<button type="button" class="ui-datepicker-close ui-state-default ui-priority-primary ui-corner-all" data-handler="hide" data-event="click">' + doneText + '</button>')
                .appendTo(els.datePicker.find('.ui-datepicker-buttonpane'));
        }
    };

    var initDatePicker = function() {
        $.datepicker._defaults.onAfterUpdate = null;
        var datepicker__updateDatepicker = $.datepicker._updateDatepicker;

        $.datepicker._updateDatepicker = function( inst ) {
            datepicker__updateDatepicker.call( this, inst );

            var onAfterUpdate = this._get(inst, 'onAfterUpdate');
            if (onAfterUpdate)
                onAfterUpdate.apply((inst.input ? inst.input[0] : null),
                    [(inst.input ? inst.input.val() : ''), inst]);
        };

        // Create the picker and align it to the bottom of the input field
        // Hide it for later
        els.datePicker.datepicker({
            defaultDate: 0,
            showButtonPanel: true,
            numberOfMonths: vars.isMobile ? 19 : 1,
            hideIfNoPrevNext: !!vars.isMobile,
            dateFormat: 'D, M dd yy',
            autoSize: true,
            minDate: 0,
            maxDate: +vars.bookingLimit,
            nextText: els.datePicker.data('label-next-month'),
            prevText: els.datePicker.data('label-prev-month'),
            beforeShow: function() {
                setDefaultDates();
            },
            beforeShowDay: function ( date ) {
                // updateNightsCount();
                // Highlight dates within min and max range
                return setRange( date );
            },
            onSelect: function(dateText, inst) {
                if ( Sugar.Date( dateText ) ) {
                    updateDates(dateText, inst);
                    updateNightsCount();
                }
            },
            onAfterUpdate: function ( inst ) {
                $('.booking-component .warning').fadeOut(250);
                els.defaultActiveDate.removeClass('ui-state-active');
                var nextPrevAttr = {
                    'href': '#',
                    'tabindex': 0,
                    'disabled': function() {
                        return $(this).hasClass('ui-state-disabled') ? 'disabled': '';
                    }
                };

                // $('.ui-datepicker-today')
                //   .addClass('ui-state-disabled')
                //   .removeAttr('data-handler data-event')
                //   .find('a').replaceWith(function(){
                //     return $("<span>" + $(this).html() + "</span>");
                //   });

                $('.current-month-title').html( $('.ui-datepicker-title').html() );

                // Next/Prev month loses focus after click because the dickpicker refreshes
                // Adding a slight timout to refocus for accessibility
                setTimeout(function(){
                    if (els.monthNavLastClicked) {
                        if (els.monthNavLastClicked.hasClass('ui-datepicker-next')) {
                            $('.ui-datepicker-next').focus();
                        }
                        else {
                            $('.ui-datepicker-prev').focus();
                        }
                    }
                },100);


                $('.ui-datepicker-header a').on({
                    click: function(evt){
                        evt.preventDefault();
                        els.monthNavLastClicked = $(this);
                    },
                    blur: function() {
                        els.monthNavLastClicked = null;
                    }
                });

                $('.ui-datepicker-prev').attr(nextPrevAttr);
                $('.ui-datepicker-next').attr(nextPrevAttr);

                if ( !vars.isMobile ) {
                    appendClearDoneButtons();
                }
            }
        })
        .css({
            top: 72,
            left: 0
        })
        .hide();
    };

    return {
        init: function(el) {
            var lang = $('html')[0].lang;
            var $el = $(el);
            var $errorMessages = $el.data('errors');

            els = {
                win: $(window),
                component: $el,
                datePicker: $el.find('.picker'),
                defaultActiveDate: $el.find('.ui-state-default'),
                dateRange: $el.find('.date-range'),
                arrivalDate: $el.find('.arrival-date'),
                departureDate: $el.find('.departure-date'),
                staylengthCount: $el.find('.stay-length-count'),

                clearDatesBtn: $el.find('.ui-datepicker-clear'),
                closeDatePicker: $el.find('.ui-datepicker-close'),
                togglePicker: $el.find('.js-toggle-date-picker'),
                dateFrom: $el.find('.date-from'),
                dateTo: $el.find('.date-to'),
                warningStayLimit: $el.find('.warning-stay-limit'),
                warningBookingLimit: $el.find('.warning-booking-limit'),
                prevMonthBtn: $el.find('.ui-datepicker-prev'),
                nextMonthBtn: $el.find('.ui-datepicker-next')
            };
            vars = {
                stayLimit: 90,
                bookingLimit: 550,
                isMobile: window.app.isMobileWindowSize(),
                isChildOfBookingComponent: els.component.parents('.booking-component').length,

                dateFromVal: null,
                dateToVal: null,
                departureDate: -1,
                arrivalDate: -1,
                currentFocusedDateField: null,

                minDate: Sugar.Date.create('Today'),
                minArrivalDate: Sugar.Date.create('Today'),
                maxArrivalDate: Sugar.Date.addDays(new Date(), 550),
                errorStayLimit: $errorMessages.stayLimit,
                errorBookingLimit: $errorMessages.bookingLimit,
                errorInvalidDate: $errorMessages.invalidDate
            };

            switch (lang) {
                case 'pt-br':
                    Sugar.Date.setLocale('pt');
                    vars.dateQueryFormat = '%d/%m/%Y';
                    $.datepicker.setDefaults( $.datepicker.regional[ 'pt-BR' ] );
                    break;
                case 'zh-CN':
                    Sugar.Date.setLocale('zh-CN');
                    vars.dateQueryFormat = '%Y-%m-%d';
                    $.datepicker.setDefaults( $.datepicker.regional[ 'zh-CN' ] );
                    break;
                case 'de-DE':
                    Sugar.Date.setLocale('de');
                    vars.dateQueryFormat = '%d.%m.%Y';
                    $.datepicker.setDefaults( $.datepicker.regional.de );
                    break;
                case 'fr-FR':
                    Sugar.Date.setLocale('fr');
                    vars.dateQueryFormat = '%d/%m/%Y';
                    $.datepicker.setDefaults( $.datepicker.regional.fr );
                    break;
                case 'it-IT':
                    Sugar.Date.setLocale('it');
                    vars.dateQueryFormat = '%d/%m/%Y';
                    $.datepicker.setDefaults( $.datepicker.regional.it );
                    break;
                case 'ja-JP':
                    Sugar.Date.setLocale('ja');
                    vars.dateQueryFormat = '%Y/%m/%d'; //yyyy/mm/dd
                    $.datepicker.setDefaults( $.datepicker.regional.ja );
                    break;
                case 'ru-RU':
                    Sugar.Date.setLocale('ru');
                    vars.dateQueryFormat = '%d.%m.%Y';
                    $.datepicker.setDefaults( $.datepicker.regional.ru );
                    break;
                case 'es-XM':
                    Sugar.Date.setLocale('es');
                    vars.dateQueryFormat = '%d/%m/%Y';
                    $.datepicker.setDefaults( $.datepicker.regional.es );
                    break;
                default:
                    $.datepicker.setDefaults( $.datepicker.regional[ '' ] );
            }

            setDefaultDates();
            initDatePicker();

            if ( !vars.isMobile ) {
                els.component.find('input').removeAttr('readonly');
            }
            listen();
        }
        // initDatePicker: initDatePicker
    };
};

$(function() {
    var $components = $('.date-select');
    if ( $components.length ) {
        $components.each(function(i,el) {
            var mrtt_datepicker = new DS.dateSelect();
            mrtt_datepicker.init(el);
        });
    }
});






// Translations
//
(function (e) {
    "function" == typeof define && define.amd ? define(["jquery"], e) : e(jQuery)
})(function (e) {
    var datepicker = e.datepicker;
    /* Brazilian initialisation for the jQuery UI date picker plugin. */
    datepicker.regional[ "pt-BR" ] = {
        closeText: "Fechar",
        prevText: "&#x3C;Anterior",
        nextText: "Próximo&#x3E;",
        currentText: "Hoje",
        monthNames: [ "Janeiro","Fevereiro","Março","Abril","Maio","Junho",
            "Julho","Agosto","Setembro","Outubro","Novembro","Dezembro" ],
        monthNamesShort: [ "Jan","Fev","Mar","Abr","Mai","Jun",
            "Jul","Ago","Set","Out","Nov","Dez" ],
        dayNames: [
            "Domingo",
            "Segunda-feira",
            "Terça-feira",
            "Quarta-feira",
            "Quinta-feira",
            "Sexta-feira",
            "Sábado"
        ],
        dayNamesShort: [ "Dom","Seg","Ter","Qua","Qui","Sex","Sáb" ],
        dayNamesMin: [ "Dom","Seg","Ter","Qua","Qui","Sex","Sáb" ],
        weekHeader: "Sm",
        dateFormat: "dd/mm/yy",
        firstDay: 0,
        isRTL: false,
        showMonthAfterYear: false,
        yearSuffix: ""
    },
        datepicker.regional[ "pt-BR" ],

        /* Russian (UTF-8) initialisation for the jQuery UI date picker plugin. */
        datepicker.regional.ru = {
            closeText: "Закрыть",
            prevText: "&#x3C;Пред",
            nextText: "След&#x3E;",
            currentText: "Сегодня",
            monthNames: [ "Январь","Февраль","Март","Апрель","Май","Июнь",
                "Июль","Август","Сентябрь","Октябрь","Ноябрь","Декабрь" ],
            monthNamesShort: [ "Янв","Фев","Мар","Апр","Май","Июн",
                "Июл","Авг","Сен","Окт","Ноя","Дек" ],
            dayNames: [ "воскресенье","понедельник","вторник","среда","четверг","пятница","суббота" ],
            dayNamesShort: [ "вск","пнд","втр","срд","чтв","птн","сбт" ],
            dayNamesMin: [ "Вс","Пн","Вт","Ср","Чт","Пт","Сб" ],
            weekHeader: "Нед",
            dateFormat: "dd.mm.yy",
            firstDay: 1,
            isRTL: false,
            showMonthAfterYear: false,
            yearSuffix: ""
        },
        datepicker.regional.ru,

        /* Chinese initialisation for the jQuery UI date picker plugin. */
        datepicker.regional[ "zh-CN" ] = {
            closeText: "关闭",
            prevText: "&#x3C;上月",
            nextText: "下月&#x3E;",
            currentText: "今天",
            monthNames: [ "一月","二月","三月","四月","五月","六月",
                "七月","八月","九月","十月","十一月","十二月" ],
            monthNamesShort: [ "一月","二月","三月","四月","五月","六月",
                "七月","八月","九月","十月","十一月","十二月" ],
            dayNames: [ "星期日","星期一","星期二","星期三","星期四","星期五","星期六" ],
            dayNamesShort: [ "周日","周一","周二","周三","周四","周五","周六" ],
            dayNamesMin: [ "日","一","二","三","四","五","六" ],
            weekHeader: "周",
            dateFormat: "yy-mm-dd",
            firstDay: 1,
            isRTL: false,
            showMonthAfterYear: true,
            yearSuffix: "年"
        },
        datepicker.regional[ "zh-CN" ],

        /* German initialisation for the jQuery UI date picker plugin. */
        datepicker.regional.de = {
            closeText: "Schließen",
            prevText: "&#x3C;Zurück",
            nextText: "Vor&#x3E;",
            currentText: "Heute",
            monthNames: [ "Januar","Februar","März","April","Mai","Juni",
                "Juli","August","September","Oktober","November","Dezember" ],
            monthNamesShort: [ "Jan","Feb","Mär","Apr","Mai","Jun",
                "Jul","Aug","Sep","Okt","Nov","Dez" ],
            dayNames: [ "Sonntag","Montag","Dienstag","Mittwoch","Donnerstag","Freitag","Samstag" ],
            dayNamesShort: [ "So","Mo","Di","Mi","Do","Fr","Sa" ],
            dayNamesMin: [ "So","Mo","Di","Mi","Do","Fr","Sa" ],
            weekHeader: "KW",
            dateFormat: "dd.mm.yy",
            firstDay: 1,
            isRTL: false,
            showMonthAfterYear: false,
            yearSuffix: ""
        },
        datepicker.regional.de,

        /* French initialisation for the jQuery UI date picker plugin. */
        datepicker.regional.fr = {
            closeText: "Fermer",
            prevText: "Précédent",
            nextText: "Suivant",
            currentText: "Aujourd'hui",
            monthNames: [ "janvier", "février", "mars", "avril", "mai", "juin",
                "juillet", "août", "septembre", "octobre", "novembre", "décembre" ],
            monthNamesShort: [ "janv.", "févr.", "mars", "avr.", "mai", "juin",
                "juil.", "août", "sept.", "oct.", "nov.", "déc." ],
            dayNames: [ "dimanche", "lundi", "mardi", "mercredi", "jeudi", "vendredi", "samedi" ],
            dayNamesShort: [ "dim.", "lun.", "mar.", "mer.", "jeu.", "ven.", "sam." ],
            dayNamesMin: [ "D","L","M","M","J","V","S" ],
            weekHeader: "Sem.",
            dateFormat: "dd/mm/yy",
            firstDay: 1,
            isRTL: false,
            showMonthAfterYear: false,
            yearSuffix: ""
        },
        datepicker.regional.fr,

        /* Italian initialisation for the jQuery UI date picker plugin. */
        datepicker.regional.it = {
            closeText: "Chiudi",
            prevText: "&#x3C;Prec",
            nextText: "Succ&#x3E;",
            currentText: "Oggi",
            monthNames: [ "Gennaio","Febbraio","Marzo","Aprile","Maggio","Giugno",
                "Luglio","Agosto","Settembre","Ottobre","Novembre","Dicembre" ],
            monthNamesShort: [ "Gen","Feb","Mar","Apr","Mag","Giu",
                "Lug","Ago","Set","Ott","Nov","Dic" ],
            dayNames: [ "Domenica","Lunedì","Martedì","Mercoledì","Giovedì","Venerdì","Sabato" ],
            dayNamesShort: [ "Dom","Lun","Mar","Mer","Gio","Ven","Sab" ],
            dayNamesMin: [ "Do","Lu","Ma","Me","Gi","Ve","Sa" ],
            weekHeader: "Sm",
            dateFormat: "dd/mm/yy",
            firstDay: 1,
            isRTL: false,
            showMonthAfterYear: false,
            yearSuffix: ""
        },
        datepicker.regional.it,

        /* Japanese initialisation for the jQuery UI date picker plugin. */
        datepicker.regional.ja = {
            closeText: "閉じる",
            prevText: "&#x3C;前",
            nextText: "次&#x3E;",
            currentText: "今日",
            monthNames: [ "1月","2月","3月","4月","5月","6月",
                "7月","8月","9月","10月","11月","12月" ],
            monthNamesShort: [ "1月","2月","3月","4月","5月","6月",
                "7月","8月","9月","10月","11月","12月" ],
            dayNames: [ "日曜日","月曜日","火曜日","水曜日","木曜日","金曜日","土曜日" ],
            dayNamesShort: [ "日","月","火","水","木","金","土" ],
            dayNamesMin: [ "日","月","火","水","木","金","土" ],
            weekHeader: "週",
            dateFormat: "yy/mm/dd",
            firstDay: 0,
            isRTL: false,
            showMonthAfterYear: true,
            yearSuffix: "年"
        },
        datepicker.regional.ja,

        /* Inicialización en español para la extensión 'UI date picker' para jQuery. */
        datepicker.regional.es = {
            closeText: "Cerrar",
            prevText: "&#x3C;Ant",
            nextText: "Sig&#x3E;",
            currentText: "Hoy",
            monthNames: [ "enero","febrero","marzo","abril","mayo","junio",
                "julio","agosto","septiembre","octubre","noviembre","diciembre" ],
            monthNamesShort: [ "ene","feb","mar","abr","may","jun",
                "jul","ago","sep","oct","nov","dic" ],
            dayNames: [ "domingo","lunes","martes","miércoles","jueves","viernes","sábado" ],
            dayNamesShort: [ "dom","lun","mar","mié","jue","vie","sáb" ],
            dayNamesMin: [ "D","L","M","X","J","V","S" ],
            weekHeader: "Sm",
            dateFormat: "dd/mm/yy",
            firstDay: 1,
            isRTL: false,
            showMonthAfterYear: false,
            yearSuffix: ""
        },
        datepicker.regional.es
});
