var DS = window.DS || {};
DS.parallax = function() {
	var els = {};
	var vars = {};

	var listen = function() {

    $(window).scroll(function() {
      window.requestAnimationFrame(writeLayout);
    });

    function writeLayout() {
      updateConfigVars();
      simpleParallax();
      moveSiteBackground();
    }
	};

	var simpleParallax = function () {
    var element = els.item;
    var startingPos = vars.startingPos;
    var scrollTop = vars.scrollTop;
		var rate = element.attr('data-parallax-rate') ? element.attr('data-parallax-rate') : vars.defaultRate;
    var scrollDiff = scrollTop + (vars.windowHeight * 0.333) - startingPos;
    var parallaxPos = scrollDiff / (rate*-1);

    if(element.attr('data-parallax-fixed')){
      parallaxPos = scrollTop / (rate*-1);
    }

    if(parallaxPos < 0 && element.attr('data-parallax-limit')){
      parallaxPos = 0;
    }
		element.css('transform', 'translate3d( 0, ' + parallaxPos + 'px, 0)');
  };

  var moveSiteBackground = function(){
    $siteBackground = els.siteBackground;
    if($siteBackground.css('position') === 'fixed'){
      var footerHeight = vars.footerHeight;
      var bodyHeight = vars.bodyHeight;
      var scrollTop = vars.scrollTop;

      if(scrollTop + vars.windowHeight > bodyHeight - footerHeight) {
        var siteBackgroundPos = scrollTop + vars.windowHeight - bodyHeight;
        siteBackgroundPos = siteBackgroundPos*-1;
      }

      if(siteBackgroundPos > 0){
        siteBackgroundPos = 0;
      } else if(siteBackgroundPos < footerHeight*-1){
        siteBackgroundPos = footerHeight*-1;
      }

      $siteBackground.css('transform', 'translate3d( -50%, ' + siteBackgroundPos + 'px, 0)');
    }
  }

  var updateConfigVars = function(){
    vars.startingPos = els.item.offset().top;
    vars.scrollTop = $(window).scrollTop();
    vars.bodyHeight = $('body').height();
    vars.footerHeight = $('.footer-component .bottom').outerHeight() + $('.footer-component .footer-meta').outerHeight();
  };

	return {
		init: function(el) {

			var $el = $(el);

			els = {
        item: $el,
        siteBackground: $('.site-background')
			};

			vars = {
        defaultRate: 5,
        startingPos: $el.offset().top,
        scrollTop: $(window).scrollTop(),
        windowHeight: $(window).height(),
        bodyHeight: $('body').height(),
        footerHeight: $('.footer-component .bottom').outerHeight() + $('.footer-component .footer-meta').outerHeight()
      };

      setTimeout(function(){
        updateConfigVars();
        simpleParallax();
      },1000);

			listen();
		}
	};
};

$(function() {
	var $items = $( '.parallax' );

	if ( $items.length > 0 ) {
			$items.each(function(i,el) {
        if (!window.app.isMobile()) {
					var parallax = new DS.parallax();
          parallax.init(el);
        }
			});
	}
});
