var DS = window.DS || {};
DS.booking = function() {
  "use strict";
  var $win = $(window);
  var $doc = $(document);
  var $body = $('body');
  var els = {};
  var vars = {};
  var locationValid = false;
  var autoCompleteSelected = false;
  var hasAutocompleteData = !(window.MARRIOTT_GEO_DATA.cities.length == 0 &&
        window.MARRIOTT_GEO_DATA.properties.length == 0 &&
        window.MARRIOTT_GEO_DATA.regions.length == 0 &&
        window.MARRIOTT_GEO_DATA.states.length == 0);

  var listen = function() {

    els.component.on({
      mousedown: function (evt) {
        // open booking component
        if ( !els.component.hasClass('expanded') ) {
          if ( vars.isMobile ) {
            evt.preventDefault();
          }
          expandBookingComponent();
          vars.tabForward = false;
        }
      },
      focusin: function(evt) {
        if ( !els.component.hasClass('expanded') ) {
          expandBookingComponent();
        }
      },
      focusout: function() {
        $('.error-message').hide();
        if( !vars.preventAutoCompleteCollapse ) {
          els.destinationList.slideUp('fast');
        }

        els.component = typeof els.component !== 'undefined' ? els.component : $('.booking-component').first();
      }
    });

    els.heading.on({
      keyup: function(evt) {
        if (evt.which === 9 && evt.shiftKey && evt.target === this) {
          els.closeBtn.focus();
        }
      }
    });

    els.destinationField.on({
      focus: function() {
        if ( !els.component.hasClass('float') ) {
          $(this).select();
        }
        $('.error-message').hide();

        if ( vars.isMobile ) {
          var panelVisible = vars.panel.hasClass('slide-in-right');
          if (!panelVisible) {
            showMobilePanel( this );
          }
          else {

          }
        }
      },
      mouseup: function() {
        if ( $(this).val().length === 0 && !vars.isMobile ) {
          showDestinationsDefaultView();
        }
      },
      keydown: function(evt) {
        // Tab into dropdown menu places/hotels toggle buttons and view all links at the bottom
        var TABKEY = 9;

        if ( evt.keyCode === TABKEY && evt.shiftKey && evt.target === this ) {
          els.destinationList.slideUp('fast');
        }
        else if (evt.keyCode === TABKEY) {
            vars.preventAutoCompleteCollapse = true;
            els.destinationList.show();
            $('.results').show();
            $('.places-btn').focus();
            els.destinationField.autocomplete( "search" );
        }

        if ( window.PRE_POPULATE ) {
          window.PRE_POPULATE = false;
        }
        if (evt.which !== TABKEY && !autoCompleteSelected) {
          validateDestination();
        }
      },
      change: function() {
        $(this).val( $(this).val().trim() );
          els.destinationField.autocomplete( "search" );
      }
    });

    els.viewAll.on({
      keyup: function(evt) {
        var TABKEY = 9;
        if (evt.keyCode === TABKEY) {
          vars.preventAutoCompleteCollapse = false;
        }
      }
    });

    els.destinationList.on({
      mouseenter: function() {
        vars.preventAutoCompleteCollapse = true;
      },
      mouseleave: function() {
        vars.preventAutoCompleteCollapse = false;
      }
    });

    els.dateSelect.on({
      click: function() {
        if (vars.isMobile) {
          var panelVisible = vars.panel.hasClass('slide-in-right');

          if (!panelVisible) {
            showMobilePanel(this);
            vars.panel.find('.date-from').focus();
          }
        }
      }
    });

    els.roomsGuestsTrigger.on({
      focus: function() {
        if(vars.isMobile) {
          return false;
        }
      },
      click: function(evt) {
        evt.preventDefault();
        if (vars.isMobile) {
          showMobilePanel( this );
        }
      }
    });

    els.closeBtn.on({
      click: function() {
        if ( !$(this).hasClass('panel-close') ) {
          collapseBookingComponent();
        }
        else {
          hideMobilePanel(this);
        }

        if (els.datePicker.is(":visible")) {
          els.datePicker.slideUp('fast');
        }
      },
      keydown: function(evt){
        evt.preventDefault();

        if(evt.target === this) {
          if (evt.which === 13 ) {
            // enter key
            collapseBookingComponent();
            if ( !$body.hasClass('home') ) {
              els.reserveBtn.focus();
            }
          }
          if (evt.which === 9) {
            // tab key
            els.heading.focus();
          }
          if (evt.which === 9 && evt.shiftKey) {
            // Shift Tab
            els.searchBtn.focus();
          }
        }
      }
    });

    $doc.on({
      mousedown: function() {
        var t                         = $(event.target);
        var isChildOfbookingComponent = t.closest( els.component ).length;
        var isCta                     = t.is( $body.find('.cta') );
        var isCloseButton             = t.is( $body.find('.close-booking') );
        var isBookingComponent        = t.is( els.component );
        var isBookingExpanded         = els.component.hasClass('expanded');
        var isSearchIconMobile        = t.is( els.searchIconMobile );
        var detailsPanelVisible       = $('.booking-component').hasClass( 'slide-in-right' );

        if (!isChildOfbookingComponent && !isBookingComponent && isBookingExpanded && !isCta && !isCloseButton && !isSearchIconMobile && !detailsPanelVisible) {
          collapseBookingComponent();
        }
      },
      keyup: function(evt) {
        // esc
        if (evt.which === 27 && !els.component.hasClass('collapsed')) {
          collapseBookingComponent();
        }
        // Store tab direction when navigating via keyboard
        if ( evt.shiftKey && evt.which === 9 ) {
          vars.tabForward = false;
        }
        else if ( evt.which === 9 ) {
          vars.tabForward = true;
        }
      }
    });

    els.reserveBtn.on({
      click: function(evt) {
        els.reserveBtnPressed = true;
        expandBookingComponent();
        els.destinationField.focus();
      },
      keydown: function(evt) {
        // Skip the booking module if tab is pressed
        if (evt.target === this) {
          if (evt.which === 9 && !els.home) {
            // tab
            els.component.find('*').attr('tabindex', '-1');
          }
          else {
              // not tab
              els.component.find('*').removeAttr('tabindex');
              els.heading.attr('tabindex', '0');
          }
          if (evt.which === 13) {
            // enter
            els.reserveBtnPressed = true;
            expandBookingComponent();
            els.closeBtn.focus();
          }
        }
      }
    });

    els.searchBtn.on({
      click: function() {
        search();

        if ( !els.roomsGuestsPanel.hasClass('is-hidden') ) {
          els.roomsGuestsTrigger.trigger('click');
        }
      },
      keydown: function(evt) {
        var code = (evt.keyCode ? evt.keyCode : evt.which);
        if (code === 9 && evt.target === this) {
          // tab
          vars.tabForward = !evt.shiftKey;
        }
      },
      blur: function() {
        if ( vars.tabForward ) {
          vars.tabForward = false;
        }
      }
    });

    els.destinationResultsToggle.on({
      click: function(evt) {
        toggleAutoCompleteListData(evt);
      }
    });

    els.regionsList.on( 'click', 'a', function(evt){
      evt.preventDefault();
      els.autoSuggestions.removeClass('show');
      els.destinationList.hide();
      els.regionsList.hide();
      els.destinationField.val( $(this).text() );

      locationValid = true;
      window.PROPERTY_ID = $(this).data('prop-id');

      if (vars.isMobile) {
        hideMobilePanel(this);
      }
    });

    els.regionsList.on( 'keyup', 'a', function(evt){
      $(this).trigger('click');
    });
  };

  var trapFocus = function() {
    var pressedKeys = {};

    onkeyup = onkeydown = function(e) {
      e = e || event; // to deal with IE
      pressedKeys[e.key] = e.type == 'keydown';

      if ( e.shiftKey && pressedKeys["Tab"] ) {
        if ( els.heading.is(':focus')) {
          els.closeBtn.focus();
          pressedKeys = {};
          return false;
        }
      }
    }
  };

  var setFocusOnClose = function() {
  var heroBtns = els.component.prevAll().find(':focusable', '.vjs-big-play-button');

  if ( els.home && !els.reserveBtnPressed ) {
    if ( !vars.tabForward ) {
      var prevFocusableEls = els.heroComponent.prevAll().find(':focusable');

      if ( heroBtns ) {
        heroBtns.each(function() {
          if ( $(this).hasClass('vjs-big-play-button') ) {
            $(this).focus();
          }
          else {
            heroBtns[heroBtns.length - 1].focus();
          }
        });
      }
      else {
        prevFocusableEls[prevFocusableEls.length - 1].focus();
      }
    }
    else {
      var nextFocusableEls = els.heroComponent.nextAll().find(':focusable');
      nextFocusableEls[0].focus();
    }
  }
  else if ( els.home && els.reserveBtnPressed ) {
    els.reserveBtn.focus();
    els.reserveBtnPressed = false;
  }
};

  var getCenterOffset = function( el ) {
    var el_y = el.offset().top + (el.height() / 2);
    var windowHeight = $win.height();
    var screenTop = $doc.scrollTop();
    var windowCenterVertical = (windowHeight/2) + screenTop;

    // Window vertical center value
    return el_y < windowCenterVertical ? windowCenterVertical - el_y : -Math.abs(windowCenterVertical - el_y);
  };

  var cloneBookingPanel = function() {
    // Clone panel
    vars.panel = els.component.clone(true, true);
    // Insert new panel
    vars.panel
      .addClass( vars.panelName + '-panel expanded animate' )
      .find('.close-booking').addClass('panel-close')
      .end().insertAfter(els.component);

    els.destinationField = els.component.next().find('.destination');
    initAutocomplete();
  };

  var showMobilePanel = function( el ) {
    $('.error-message').hide();
    els.destinationField.attr('readonly', false);

    if (window.PRE_POPULATE_PROPERTY && !els.destinationField.val()) {
      els.destinationField.val(window.PRE_POPULATE_PROPERTY);
    }

    vars.panelName = $(el).data('trigger-panel');
    vars.dataAttr = 'mobile-panel-heading-' + vars.panelName;

    vars.panel.attr('class',
      function (i, c) {
        return c.replace(/\w*-panel\w*/, vars.panelName + '-panel');
      });

    // Slide out prev panel
    vars.panel.prev().addClass('animate slide-out-left');
    vars.panel.addClass('slide-in-right');
    // Update cloned panel contents
    vars.panelTitle = els.component.find('.heading').data(vars.dataAttr);
    // Update panel title
    vars.panel.find('.heading').text(vars.panelTitle);

    vars.panel
      .one('animationend webkitAnimationEnd oAnimationEnd MSAnimationEnd',
      function () {
        // Reassign elements for cloned details panel
        els.destinationField = vars.panel.find('.destination');
        els.noDestinationMessage = vars.panel.find(".empty-message");
        initAutocomplete();
        // DS.dateSelect.initDatePicker();
      }
    );
  };

  var hideMobilePanel = function(_this) {
    if (window.PRE_POPULATE_PROPERTY && !els.destinationField.val()) {
      els.destinationField.val(window.PRE_POPULATE_PROPERTY);
    }

    els.destinationField.attr('readonly', true);
    vars.panel.prev().removeClass('slide-out-left');
    vars.panel.removeClass('slide-in-right');

    var parent = $(_this).parents('.booking-component');
    var bookingForm = parent.prev().find('.booking-form');
    var updatedBookingForm = parent.find('.booking-form');
    var clonedForm = updatedBookingForm.clone(true);

    // Update rooms and guests selected values after cloning
    updatedBookingForm.find('select').each(function(i) {
      clonedForm.find('select').eq(i).val($(this).val());
    });

    bookingForm.replaceWith(clonedForm);
  };

  var expandBookingComponent = function() {
    var top;
    els.component = typeof els.component !== 'undefined' ? els.component : $('.booking-component').first();
    els.bgDimmer = typeof els.bgDimmer !== 'undefined' ? els.bgDimmer : $('.booking-component').first().siblings('.bg-dimmer');

    if ( !window.app.isMobileWindowSize() ) {
      els.bgDimmer.fadeIn(250);

      els.component
        .removeClass('collapsed is-hidden')
        .addClass('expanded');

      if (els.closeBtn) els.closeBtn.focus();

      if ( els.component.hasClass('float') ) {
        els.component.css({
          top: $doc.scrollTop() + ($win.height() / 2) - (els.component.height()/2)
        });
      }
      else {
        top = getCenterOffset( els.component );
        els.component.css({
          transform: 'translateY(' + top + 'px )'
        });
      }
    } else {
      els.component
        .removeClass('collapsed')
        .addClass('expanded animate expanding')
        .one('animationend webkitAnimationEnd oAnimationEnd MSAnimationEnd',
          function() {
            $(this).removeClass('animate expanding');
          }
        );

      if( !$('html').hasClass('no-scroll') ) {
        $('html').addClass('no-scroll');
      }
    }
    trapFocus();
  };

  var collapseBookingComponent = function() {
    var navOpen = $('.header-component').hasClass('mobile-nav-open');
    var mapShown = $('.directory-component').hasClass('show-mini-map');

    $('.booking-component .warning').fadeOut(250);

    if(!navOpen && !mapShown) {
      $('html').removeClass('no-scroll');
    }
    $('.error-message').hide();

    if ( !vars.isMobile ) {
      els.bgDimmer.fadeOut(250);

      if ( !els.roomsGuestsPanel.hasClass('is-hidden') ) {
        els.roomsGuestsPanel.slideToggle("fast")
          .toggleClass('is-hidden')
          .parents(".form-group")
          .find('.dropdown-trigger')
          .toggleClass('dropdown-is-active');
      }
      if ( els.destinationList.is(":visible") ) {
        els.destinationList.hide();
      }
      if (els.datePicker.is(":visible")) {
          els.datePicker.slideUp('fast');
      }

      els.component.css({
        transform: 'translateY( 0px )'
      })
      .removeClass('expanded')
      .addClass('collapsed')
      .one('animationend webkitAnimationEnd oAnimationEnd MSAnimationEnd',
        function() {
          if ($(this).hasClass( 'collapsed' )) {
            $(this).addClass('is-hidden');
          }
        }
      );
      setFocusOnClose();
    }
    else {
      els.component
        .addClass('animate collapsing')
        .one('animationend webkitAnimationEnd oAnimationEnd MSAnimationEnd',
          function() {
            $(this)
              .removeClass('animate expanded collapsing')
              .addClass('collapsed');
          }
        );
    }
  };

  var getPlaces = function() {
    var MGD = window.MARRIOTT_GEO_DATA;

    var places = $.map(MGD.cities, function(value, key){
      if ( key !== 'niagara_falls' ) {
        if ( getState(value.state) !== null && value.country !== 'GB' ) {
          return value.name + ', ' + getState(value.state) + ', ' + getCountry(value.country);
        }
        else {
          return value.name + ', ' + getCountry(value.country);
        }
      }
    });

    // Function to get Niagara Falls destinations
    // Since there are two destination for Niagara Falls but only one entry in MARRIOTT_GEO_DATA cities object
    getNiagaraFallsDestinations( places );

    $.each(MGD.countries, function(countryKey, countryVal){
      places.push( countryVal.name );
    });
    $.each(MGD.states, function(stateKey, stateVal){
      places.push( stateVal.name );
    });
    $.each(MGD.regions, function(regionKey, regionVal){
      places.push( regionVal );
    });

    return places;
  };

  var getNiagaraFallsDestinations = function(array) {
    var MGD = window.MARRIOTT_GEO_DATA;
    $.map(MGD.properties, function(value){
      if ( value.city === "niagara_falls" ) {
        array.push( getCity( value.city ) +', '+ value.state +', '+ getCountry( value.country ) );
      }
    });
  };

  var getProperties = function( regionCode ) {
    var MGD = window.MARRIOTT_GEO_DATA;
    var regionsList = els.regionsList.find("[data-region='" + regionCode + "']");

    // Populate properties for regions
    // Used in the default list when user click in the destination field
    if ( regionCode ) {
     $.each(MGD.properties, function( key, value ) {

       if ( value.region === regionCode ) {
         var property = value.name + ', ' + getCity( value.city ) + ', ' + getCountry( value.country );

         regionsList.append(
           '<li class="ui-menu-item">' +
           '<a class="ui-corner-all ui-menu-item-wrapper" href="#" data-prop-id="'+ key +'">' + property +
           '</a></li>' );
       }

     });
    }
    else {
      // Return all properties
      return $.map(MGD.properties, function(value, key){
        return getState( value.state ) !== null ?
          { id: key, value: value.name + ', ' + getCity( value.city ) + ', ' + getState( value.state ) }
            :
          { id: key, value: value.name + ', ' + getCity( value.city ) + ', ' + getCountry( value.country ) };
      });
    }
  };

  var getCity = function( cityCode ) {
    var city = null;
    $.each( window.MARRIOTT_GEO_DATA.cities, function(cityKey, cityVal) {
      if ( cityKey === cityCode ) {
        city = cityVal.name;
      }
    });
    return city;
  };

  var getState = function( stateCode ) {
    var state = null;
    $.each( window.MARRIOTT_GEO_DATA.states,
      function(stateKey, stateData){
        if ( stateKey !== null && stateKey === stateCode ) {
          state = stateData.name;
        }
      }
    );
    return state;
  };

  var getCountry = function( countryCode ) {
    var country = null;
    $.each( window.MARRIOTT_GEO_DATA.countries,
      function(countryKey, countryVal){
        if ( countryKey === countryCode ) {
          country = countryVal.name;
        }
      }
    );
    return country;
  };

  var toggleAutoCompleteListData = function(evt) {
    evt.preventDefault();
    var $thisButton = $(evt.target);
    var list = $(evt.target).data('list');

    window.SEARCH_TYPE = list === 'places' ? 'location' : 'prop';

    if( !$thisButton.hasClass('selected') ) {
      $thisButton
        .addClass('selected')
        .siblings().removeClass('selected');
    }

    els.destinationField.autocomplete( "option",
      { source: list === 'places' ? getPlaces() : getProperties() }
    );
    els.destinationField.autocomplete( "search" );
  };

  var showDestinationsDefaultView = function() {
      els.destinationList.show();
      els.destinationList.find('.dest-default-view').show();
      els.regionsList.show();
      els.autoSuggestions.removeClass('show');
  };

  var populateRegions = function() {
    $.each( window.MARRIOTT_GEO_DATA.regions, function( regionKey, val ) {
      els.regionsList.append('<div class="region-label">' + val.name + '</div><ul data-region="' + regionKey + '"/>');
      getProperties( regionKey );
    });
  };

  var initAutocomplete = function() {
    // Custom autocomplete instance.
    // Custom highlight on matched characters
    $.widget( "app.autocomplete", $.ui.autocomplete, {

      // Which class get's applied to matched text in the menu items.
      options: {
        highlightClass: "ui-state-highlight"
      },

      _renderItem: function( ul, item ) {
        // Replace the matched text with a custom span. This
        // span uses the class found in the "highlightClass" option.
        // var re = new RegExp("(" + this.term + ")", "gi");
        var re = new RegExp('(\\S*' + this.term + '\\S*)', 'gi');

        var cls      = this.options.highlightClass;
        var template = "<span class='" + cls + "'>$1</span>";
        var label    = item.label.replace(re, template);
        var div      = '<div data-prop-id="' + item.id + '"/>';
        var $li;

        // Move matches that appears at the beginning of result string to top of list
        if ( item.label.toUpperCase().indexOf(this.term.toUpperCase()) === 0 ) {
            $li = $("<li/>").prependTo(ul);
        }
        else {
            $li = $("<li/>").appendTo(ul);
        }

        // Create and return the custom menu item content.
        $(div).html(label).appendTo($li).end();

        return $li;
      }
    });

    // Overrides the default autocomplete filter function to search for matched on atleast 1 word in each of the input term's words
    $.ui.autocomplete.filter = function (array, terms) {
      var arrayOfTerms = terms.split(" ");
      var term = $.map(arrayOfTerms, function (tm) {
        if (tm.length) {
          return $.ui.autocomplete.escapeRegex(tm);
        }
      }).join('|');
      var matcher = new RegExp("\\b" + term, "i");
      return $.grep(array, function (value) {
        return matcher.test(value.label || value.value || value);
      });
    };

    var field = els.destinationField;

    // Init autocomplete
    field.autocomplete({
      // This shows the min length of characters that must be typed before the autocomplete looks for a match.
      minLength: 2,
      autoFocus: true,
      appendTo: field.parent().find('.results'),
      source: getPlaces(),
      response: function(event, ui) {
        // ui.content is the array that's about to be sent to the response callback.
        if (ui.content.length === 0) {
          field.parent().find('.results').hide();
          field.parent().find(".empty-message").show();
          locationValid = false;
        } else {
          validateDestination(ui.content, field.val());
          field.parent().find(".empty-message").hide();
        }
      },
      search: function() {
        field.parent().find('.dest-list').show();
        field.parent().find('.dest-default-view').hide();
        field.parent().find('.autosuggest').addClass('show');
        field.parent().find('.results').show();
      },
      select: function(e, ui) {
        e.stopPropagation();

          var TABKEY = 9;
          if (e.keyCode == TABKEY) {
              e.preventDefault();
          }
          else {
              els.component.find('.destination').val( ui.item.value );
              window.PROPERTY_ID = ui.item.id ? ui.item.id : '';
              locationValid = true;
              autoCompleteSelected = true;
          }

        if (!vars.isMobile) {
          field.parent().find('.dest-list').hide();
        }
        else {
          hideMobilePanel(els.component);
        }
      },
      close: function() {
        // Close
      }
    });
  };

  var validateDestination = function (obj) {
    var val = els.destinationField.val().toLowerCase();

    if (obj) {
      if (obj.length === undefined) {
        locationValid = false;
      }
      else {
        for (var k in obj) {
          if (!obj.hasOwnProperty(k)) {
            continue;
          }

          if (typeof obj[k] !== 'undefined' && typeof obj[k].value !== 'undefined') {
            var str = obj[k].value.toLowerCase();

            if (decodeURI(str).indexOf(val) !== -1) {
              locationValid = true;
            }
          }
        }
      }
    }
    else {
      locationValid = false;
    }
  };

  var prepopulateDestination = function(propId) {
    var property = null;

    $.each( window.MARRIOTT_GEO_DATA.properties,
      function(propKey, propVal){
        if ( parseInt(propId) === parseInt(propKey) ) {
          property = propVal.name + ', ' + getCity( propVal.city ) + ', ' + getCountry( propVal.country );
          window.PROPERTY_ID = propKey;
          window.SEARCH_TYPE = 'prop';
          window.PRE_POPULATE_PROPERTY = property;
        }
      }
    );
    els.component.find('.destination').val( property );
    window.PRE_POPULATE = true;
  };

  var search = function () {
    var val = els.destinationField.val().toLowerCase();

    if (window.PRE_POPULATE || !hasAutocompleteData) {
      locationValid = true;
    }
    else if (!val.length) {
      locationValid = false;
    }

    if ( locationValid ) {
      var destination = els.destinationField.val();
      var countryCodes = window.MARRIOTT_GEO_DATA.country_codes;
      var countryCode = countryCodes[destination];
      if (countryCode) {
        els.countryCodeInput.val(countryCode);
      } else {
        els.countryCodeInput.val('');
      }
      var str = els.component.not('[class*="-panel"]').find('.form').serialize();
      var brandCode = els.component.attr('data-brand');
      var url = els.component.attr('data-url');
      var propIds = window.PROPERTY_ID || '';
      var lang = $('html')[0].lang;

      window.SEARCH_TYPE = 'InCity';
      var searchUrl = url + (url.indexOf('?') > -1 ? '&' : '?') + 'searchType=' + window.SEARCH_TYPE + '&showMore=true' + '&groupCode=&resultsMap=' +'&marriottBrands=' + brandCode + '&' + str;

      console.log(searchUrl);

      window.open(searchUrl,
        '_blank'
      );
    }
    else {
      $('.error-message').show();
    }
  };

  var init = function(el) {
    var $el = $(el);
    var $home = $el.parents('.home');

    els = {
      component: $el,
      home: $home,
      bgDimmer: $body.find('.bg-dimmer'),
      heroComponent: $body.find('.hero-component'),
      reserveBtn: $body.find('.reserve.cta'),
      reserveBtnPressed: false,
      cta: $body.find('.cta'),
      slideAccessory: $body.find('.slide-accessory'),
      homeHeroComponent: $body.find('.home.hero-component'),

      heading: $el.find('.heading'),
      closeBtn: $el.find('.close-booking'),
      input: $el.find('input'),
      formGroup: $el.find('.form-group'),
      hotelsBtn: $el.find('.hotelsBtn'),
      placesBtn: $el.find('.placesBtn'),
      searchBtn: $el.find('.search-btn'),
      roomsGuestsTrigger: $el.find('.js-rooms-guests-trigger'),
      roomsGuestsPanel: $el.find('.js-rooms-guests-panel'),

      countryCodeInput: $el.find('input[name="destinationAddress.country"]'),
      destinationField: $el.find('.destination'),
      destinationResultsToggle: $el.find('.results-toggle'),
      destinationList: $el.find('.dest-list'),
      autoSuggestions: $el.find('.autosuggest'),
      noDestinationMessage: $el.find(".empty-message"),
      destinationDefaultView: $el.find('.dest-default-view'),
      regionsList: $el.find('.regions'),
      errorMessage: $el.find('.error-message'),

      dateSelect: $el.find('.date-select'),
      datePicker: $el.find('.date-select .picker'),
      dateRange: $el.find('.date-range'),
      viewAll: $el.find('.view-all')
    };

    vars = {
      searchType: 'prop',
      isMobile: window.app.isMobileWindowSize(),
      hasHomeHero: els.homeHeroComponent.length,
      tabForward: false,
      searchBtnFocus: false
    };

    window.SEARCH_TYPE = 'location';

    if ( vars.hasHomeHero ) {
      els.component.appendTo( els.homeHeroComponent );
      els.bgDimmer.appendTo( els.homeHeroComponent );
    }
    else {
      els.component.insertAfter( els.heroComponent );
      els.component.addClass('float');
      els.component.after( els.bgDimmer );
    }

    populateRegions();
    initAutocomplete();
    listen();

    if ( vars.isMobile ) {
      els.destinationField.attr('readonly', true);
      els.destinationField.autocomplete('destroy');
      cloneBookingPanel();
    }
  };

  return {
    init: init,

    // public function that accepts a prop id
    // and opens the booking component with that property populated
    openBookingComponent: function(propId) {
      expandBookingComponent();
      if (propId) {
        prepopulateDestination(propId);
        if ( !window.app.isMobileWindowSize() ) {
          $('.destination').focus();
        }
      }
    },
    closeBookingComponent: function() {
      $('.booking-component').find('.close-booking').click();
    }
  };
};

$(function() {
  "use strict";
  var $components = $('.booking-component');
  if ( $components.length ) {
    $components.each(function(i,el) {
      var booking = new DS.booking();
      booking.init(el);
    });
  }
});
